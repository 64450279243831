import { graphql } from 'gatsby';
import DynamicDate from "../../../../src/pages/project/global-track/dynamic-date";
import { Badge, Link, Text } from '@chakra-ui/layout';
import { FaExternalLinkAlt } from 'react-icons/fa';
import * as React from 'react';
export default {
  graphql,
  DynamicDate,
  Badge,
  Link,
  Text,
  FaExternalLinkAlt,
  React
};