module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/Users/wilmer/projects/wilmercantillo.com/src/components/md/index.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1200,"backgroundColor":"transparent"}}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/wilmer/projects/wilmercantillo.com"},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"isResettingCSS":true,"isUsingColorMode":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#cc1a27","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wilmer Cantillo","short_name":"wilmercantillo","description":"Come check out how Wilmer Cantillo can help you level up your career as a software engineer.","start_url":"/","lang":"es","background_color":"#ffffff","theme_color":"#cc1a27","icon":"static/images/logo.png","display":"standalone","icons":[{"src":"static/icons/manifest-icon-192.png","sizes":"192x192","type":"image/png","purpose":"maskable any"},{"src":"static/icons/manifest-icon-512.png","sizes":"512x512","type":"image/png","purpose":"maskable any"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5b30f03e0ac62d1452c3b6e6f18f7583"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
