// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-post-demo-post-index-en-mdx": () => import("./../../../src/pages/post/demo-post/index.en.mdx" /* webpackChunkName: "component---src-pages-post-demo-post-index-en-mdx" */),
  "component---src-pages-post-demo-post-index-mdx": () => import("./../../../src/pages/post/demo-post/index.mdx" /* webpackChunkName: "component---src-pages-post-demo-post-index-mdx" */),
  "component---src-pages-project-caja-naranja-index-en-mdx": () => import("./../../../src/pages/project/caja-naranja/index.en.mdx" /* webpackChunkName: "component---src-pages-project-caja-naranja-index-en-mdx" */),
  "component---src-pages-project-caja-naranja-index-mdx": () => import("./../../../src/pages/project/caja-naranja/index.mdx" /* webpackChunkName: "component---src-pages-project-caja-naranja-index-mdx" */),
  "component---src-pages-project-cardiac-signals-analysis-index-en-mdx": () => import("./../../../src/pages/project/cardiac-signals-analysis/index.en.mdx" /* webpackChunkName: "component---src-pages-project-cardiac-signals-analysis-index-en-mdx" */),
  "component---src-pages-project-cardiac-signals-analysis-index-mdx": () => import("./../../../src/pages/project/cardiac-signals-analysis/index.mdx" /* webpackChunkName: "component---src-pages-project-cardiac-signals-analysis-index-mdx" */),
  "component---src-pages-project-global-track-dynamic-date-tsx": () => import("./../../../src/pages/project/global-track/dynamic-date.tsx" /* webpackChunkName: "component---src-pages-project-global-track-dynamic-date-tsx" */),
  "component---src-pages-project-global-track-index-en-mdx": () => import("./../../../src/pages/project/global-track/index.en.mdx" /* webpackChunkName: "component---src-pages-project-global-track-index-en-mdx" */),
  "component---src-pages-project-global-track-index-mdx": () => import("./../../../src/pages/project/global-track/index.mdx" /* webpackChunkName: "component---src-pages-project-global-track-index-mdx" */),
  "component---src-pages-project-modbus-lora-communication-index-en-mdx": () => import("./../../../src/pages/project/modbus-lora-communication/index.en.mdx" /* webpackChunkName: "component---src-pages-project-modbus-lora-communication-index-en-mdx" */),
  "component---src-pages-project-modbus-lora-communication-index-mdx": () => import("./../../../src/pages/project/modbus-lora-communication/index.mdx" /* webpackChunkName: "component---src-pages-project-modbus-lora-communication-index-mdx" */),
  "component---src-pages-project-orange-delivery-index-en-mdx": () => import("./../../../src/pages/project/orange-delivery/index.en.mdx" /* webpackChunkName: "component---src-pages-project-orange-delivery-index-en-mdx" */),
  "component---src-pages-project-orange-delivery-index-mdx": () => import("./../../../src/pages/project/orange-delivery/index.mdx" /* webpackChunkName: "component---src-pages-project-orange-delivery-index-mdx" */),
  "component---src-pages-project-parabolic-motion-simulation-index-en-mdx": () => import("./../../../src/pages/project/parabolic-motion-simulation/index.en.mdx" /* webpackChunkName: "component---src-pages-project-parabolic-motion-simulation-index-en-mdx" */),
  "component---src-pages-project-parabolic-motion-simulation-index-mdx": () => import("./../../../src/pages/project/parabolic-motion-simulation/index.mdx" /* webpackChunkName: "component---src-pages-project-parabolic-motion-simulation-index-mdx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */)
}

